<template>
  <v-card class="mx-auto pa-5">
    <!--    <v-card-title class="mx-0 px-0">Create New Order</v-card-title>
        <hr>-->
    <v-row>
      <v-col cols="12" sm="6" class="pl-1 pr-2">
        <h3 class="title-col mb-2 pl-3">Thông tin chung</h3>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Dịch Vụ
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-select disabled v-model="item.display_service_id" :items="listDisplayService" class="select-status" dense item-text="name" item-value="id"
                      outlined></v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Tên hóa đơn
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="item.name" dense outlined
                          placeholder="(Giúp bạn dễ nhớ, dễ tìm đơn hàng)"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Giá trị đơn hàng (USD)
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field placeholder="đơn vị USD" dense outlined
                          v-model="item.price"
                          v-currency="{
                                locale: 'en-US',
                                currency: 'USD',
                                valueRange: { min: 0 },
                                allowNegative: false
                            }"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Bảo hiểm hàng hóa
            </v-subheader>
          </v-col>
          <v-col cols="8" style="font-size: 1rem;">
            <v-switch v-model="item.is_insurance" label="Nếu mua bảo hiểm hàng hóa, hệ thống sẽ tính phí 10% số tiền dựa theo giá trị đơn hàng"></v-switch>
            <!--            <v-tooltip bottom v-if="item.note">
                          <template v-slot:activator="{ on, attrs }">

                          </template>
                          <span>Nếu mua bảo hiểm hàng hóa, hệ thống sẽ tính phí 10% số tiền dựa theo giá trị đơn hàng</span>
                        </v-tooltip>-->

          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Địa chỉ trả hàng</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="item.return_address" dense outlined
                          placeholder="Địa chỉ nhận lại nếu không giao hàng thành công"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Ghi chú</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-textarea v-model="item.note" :rows="2" dense outlined></v-textarea>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Ghi chú của khách hàng</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-textarea v-model="item.customer_note" :rows="2" dense outlined></v-textarea>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" class="pl-2 pr-1">
        <h3 class="title-col mb-2 pl-3">Thông tin người nhận</h3>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Tên người nhận
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="item.receiver_name" dense outlined></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Số điện thoại</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="item.receiver_phone" maxlength="50" dense outlined></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Địa chỉ nhận hàng
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-textarea v-model="item.receiver_address" :rows="2" dense outlined
                        placeholder="Chỉ gồm tên đường số nhà. Ví dụ: 520 Meador Dr Apt B"></v-textarea>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4"></v-col>
          <v-col cols="8">
            <v-row no-gutters>
              <v-col cols="12" md="5">
                <v-text-field v-model="item.receiver_city" dense label="Thành phố *"
                              outlined></v-text-field>
                <v-text-field v-model="item.receiver_state" dense label="Bang *" outlined></v-text-field>
              </v-col>
              <v-col cols="12" md="5" offset-md="2">
                <v-text-field v-model="item.receiver_post_code" dense label="Mã bưu điện *"
                              outlined></v-text-field>
                <v-autocomplete disabled v-model="item.receiver_country_id" :items="countries" dense item-text="name"
                                item-value="id"
                                label="Quốc gia*" outlined>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Ghi chú</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-textarea v-model="item.receiver_note" :rows="2" dense outlined></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <hr class="mb-2">
    <h3 class="title-col mb-2 pl-3">Thông tin kiện hàng</h3>
    <v-row>
      <v-col cols="12" sm="5" lg="5">
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Nhận hàng vận chuyển từ</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-select
                v-model="item.income_shipment_id"
                :items="incomeShipments"
                dense
                item-text="name"
                item-value="id"
                outlined>
            </v-select>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Mã vận đơn gửi đến</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="item.income_shipment_code" dense outlined required></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Tên kiện hàng
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="item.package_name" dense outlined></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Kho nhận hàng
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
<!--            <v-select v-model="item.warehouse_id" :items="warehouses"
                      dense item-text="name" item-value="id" outlined></v-select>-->
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Cước vận chuyển</v-subheader>
          </v-col>
          <v-col cols="8" class="py-3">
            <div v-if="isNaN(parseInt(shippingFee))">{{ shippingFee }}</div>
            <money-format v-else :value="parseInt(shippingFee)" style="display: inline-block;"
                          :hide-subunits=true :locale="'vi'" :currency-code="'VND'"></money-format>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="0" lg="1"></v-col>
      <v-col cols="12" sm="5" lg="5">
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Chiều dài (cm)
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field placeholder="cm" v-model="item.length" dense outlined type="number"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Chiều rộng (cm)
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field placeholder="cm" v-model="item.width" dense outlined type="number"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Chiều cao (cm)
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field placeholder="cm" v-model="item.height" dense outlined type="number"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <v-subheader>Cân nặng (g)
              <div class="red--text">*</div>
            </v-subheader>
          </v-col>
          <v-col cols="8">
            <v-text-field placeholder="gram" v-model="item.weight" dense min=0 outlined type="number"></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <hr class="mb-2">
    <h3 class="title-col mb-2">
      Chi tiết hàng hóa
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" style="font-size: 2.25rem;margin-left: 1rem;"
                  v-on="on"
                  @click="addOrderItem" color="success">mdi-plus
          </v-icon>
        </template>
        <span>Ấn vào đây để thêm hàng hóa</span>
      </v-tooltip>
    </h3>
    <v-row>
      <v-col v-for="(selectedItem, idx) in item.order_items" :key="idx" cols="12" sm="4">
        <CreateOrderItem :selectedItem="selectedItem" :idx="idx" @removeOrderItem="removeOrderItem"></CreateOrderItem>
      </v-col>
    </v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-checkbox
          v-model="isAcceptCondition"
          color="primary"
          hide-details
      >
        <template v-slot:label>
          <div>
            Tôi đồng ý với các
            <a
                target="_blank"
                href="#"
                @click.stop
                v-on="on"
            >
              điều khoản & điều kiện.
            </a>
          </div>
        </template>
      </v-checkbox>
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onClickCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="processAdd" :disabled="!CanCreateOrder">Create</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import CreateOrderItem from "./CreateOrderItem";
import MoneyFormat from "vue-money-format";

export default {
  name: 'CreateOrder',
  components: {CreateOrderItem, MoneyFormat},
  props: ['onCreated', 'onCancel', 'selectedItem', 'showModal', 'isCopyOrder'],
  data: () => ({
    item: {
      is_insurance: false,
      income_shipment_id: 3,
      warehouse_id: 1,
      display_service_id: 'LT',
      customer_note: '',
      order_items: [
        {
          name: '',
          link: '',
          price: '',
          quantity: ''
        }
      ]
    },
    shippingFee: '',
    checkShippingFeeTime: 0,
    isAcceptCondition: true,
  }),
  computed: {
    ...mapGetters({
      services: 'service/all',
      countries: 'country/all',
      getCountryCode: 'country/getCode',
      incomeShipments: 'income_shipment/all',
      warehouses: 'warehouse/all',
    }),
    listDisplayService() {
      return [
        {id: 'LT', name: 'Lastmile Tracking'},
        /*{id: 'EP', name: 'Epacket'},
        {id: 'DE', name: 'DHL Ecom'},*/
      ]
    },
    paramsToGetShippingFee() {
      return {
        length: this.item.length,
        width: this.item.width,
        height: this.item.height,
        weight: this.item.weight,
        rank: this.USER.role_id === this.CONSTANTS.ROLES.CUSTOMER || this.USER.role_id === this.CONSTANTS.ROLES.RECEIVER ? this.USER.rank_id : -1,
        service_id: this.item.service_id,
        country: this.item.receiver_country_id,
        is_insurance: this.item.is_insurance,
        price: this.item.price ? this.item.price.replace('$', '') : null,
      }
    },
    CanCreateOrder() {
      //nếu là ship express thì không kiểm tra giá order
      if (this.item.service_id === this.CONSTANTS.SERVICE_TYPE.SHIP_EXPRESS) return this.isAcceptCondition === true
      return this.isAcceptCondition === true && typeof this.shippingFee === 'number';
    }
  },
  methods: {
    ...mapActions({
      createItem: 'order/create',
      calcShippingFees: 'order/calcShippingFees',
    }),
    resetData() {
      this.item = {
        income_shipment_id: 3,
        warehouse_id: 1,
        display_service_id: 'LT',
        service_id: 7,
        receiver_country_id: 226,
        order_items: [
          {
            name: '',
            link: '',
            price: '',
            quantity: ''
          }
        ]
      }
    },
    processAdd() {
      this.createItem({
        ...this.item,
        price: this.item.price ? this.item.price.replace('$', '') : ''
      }).then(() => {
        this.resetData();
        if(this.onCreated) return this.onCreated();
      });
    },
    onClickCancel() {
      if (this.onCancel) this.onCancel();
    },
    addOrderItem() {
      this.item.order_items.push({
        name: '',
        link: '',
        price: '',
        quantity: ''
      })
    },
    removeOrderItem(e) {
      if (this.item.order_items.length > 1) {
        this.item.order_items.splice(e, 1)
      }
    },
    fillService() {
      let countryCode = this.getCountryCode(this.item.receiver_country_id);
      if (this.item.display_service_id === 'LT') {
        this.item.receiver_country_id = 226;
        this.item.service_id = 7;
      } else if (this.item.display_service_id === 'EP') {
        if (countryCode === 'US') this.item.service_id = this.CONSTANTS.SERVICE_TYPE.EPACKET_US
        else if (countryCode === 'AU') this.item.service_id = this.CONSTANTS.SERVICE_TYPE.EPACKET_AU
        else this.item.service_id = this.CONSTANTS.SERVICE_TYPE.EPACKET_WW
      } else if (this.item.display_service_id === 'DE') {
        if (countryCode === 'US') this.item.service_id = this.CONSTANTS.SERVICE_TYPE.DHL_ECOM_US
        else this.item.service_id = this.CONSTANTS.SERVICE_TYPE.DHL_ECOM_WW
      } else this.item.service_id = this.CONSTANTS.SERVICE_TYPE.SHIP_EXPRESS
    },
    handleCopyOrder() {
      this.item = JSON.parse(JSON.stringify(this.selectedItem));
      if (typeof this.item.tracking_number !== 'undefined') delete this.item.tracking_number;
      if (this.item.tracking_number && this.item.tracking_number !== '') this.item.tracking_number = '';

      this.item.buy_tracking_status = 0;
      this.item.status = 0;
      this.item.register_tracking_status = 0;
      this.item.receive_date = '';
      this.item.departed_date = '';

      if (this.item.income_shipment_id === null) this.item.income_shipment_id = 3;
      if (this.item.order_items && this.item.order_items.length > 0) {
        for (let i = 0; i < this.item.order_items.length; i++) {
          const oderInfo = (({name, link, price, quantity}) => ({name, link, price, quantity}))(this.item.order_items[i]);
          this.item.order_items[i] = oderInfo;
        }
      }
      if (!this.selectedItem.service_id) this.item.display_service_id = 'ES';
      else {
        if (
            this.selectedItem.service_id === this.CONSTANTS.SERVICE_TYPE.EPACKET_US ||
            this.selectedItem.service_id === this.CONSTANTS.SERVICE_TYPE.EPACKET_AU ||
            this.selectedItem.service_id === this.CONSTANTS.SERVICE_TYPE.EPACKET_WW
        ) {
          this.item.display_service_id = 'EP';
        } else if (
            this.selectedItem.service_id === this.CONSTANTS.SERVICE_TYPE.DHL_ECOM_US ||
            this.selectedItem.service_id === this.CONSTANTS.SERVICE_TYPE.DHL_ECOM_WW ||
            this.selectedItem.service_id === this.CONSTANTS.SERVICE_TYPE.SHIP_EXPRESS
        ) {
          this.item.display_service_id = 'DE';
        } else this.item.display_service_id = 'ES';
      }
      // hand filed order copy
      const orderCopy = {
        customer_note: this.item.customer_note ? this.item.customer_note : '',
        display_service_id: this.item.display_service_id ? this.item.display_service_id : '',
        height: this.item.height ? this.item.height : '',
        income_shipment_id: this.item.income_shipment_id ? this.item.income_shipment_id : '',
        income_shipment_code: this.item.income_shipment_code ? this.item.income_shipment_code : '',
        is_insurance: this.item.is_insurance ? this.item.is_insurance : 0,
        length: this.item.length ? this.item.length : '',
        name: this.item.name ? this.item.name : '',
        note: this.item.note ? this.item.note : '',
        order_items: this.item.order_items ? this.item.order_items : [],
        package_name: this.item.package_name ? this.item.package_name : '',
        price: this.item.price ? this.item.price : '',
        receiver_address: this.item.receiver_address ? this.item.receiver_address : '',
        receiver_city: this.item.receiver_city ? this.item.receiver_city : '',
        receiver_country_id: this.item.receiver_country_id ? this.item.receiver_country_id : '',
        receiver_name: this.item.receiver_name ? this.item.receiver_name : '',
        receiver_note: this.item.receiver_note ? this.item.receiver_note : '',
        receiver_phone: this.item.receiver_phone ? this.item.receiver_phone : '',
        receiver_post_code: this.item.receiver_post_code ? this.item.receiver_post_code : '',
        receiver_state: this.item.receiver_state ? this.item.receiver_state : '',
        return_address: this.item.return_address ? this.item.return_address : '',
        service_id: this.item.service_id ? this.item.service_id : 0,
        /*warehouse_id: this.item.warehouse_id ? this.item.warehouse_id : 0,*/
        weight: this.item.weight ? this.item.weight : 0,
        width: this.item.width ? this.item.width : 0,
      }
      this.item = JSON.parse(JSON.stringify(orderCopy));
    },
  },
  watch: {
    'item.receiver_country_id': {
      handler() {
        if (!this.item.display_service_id) return;
        this.fillService();
      },
      deep: true,
      immediate: true
    },
    'item.display_service_id': {
      handler() {
        if (!this.item.receiver_country_id) return;
        this.fillService();
      },
      deep: true,
      immediate: true
    },
    paramsToGetShippingFee: {
      async handler(val) {
        if (val.rank < 0) return this.shippingFee = 'Lỗi nhận diện khách hàng';
        let missingFields = [];
        if (!val.length) missingFields.push('chiều dài');
        if (!val.width) missingFields.push('chiều rộng');
        if (!val.height) missingFields.push('chiều cao');
        if (!val.weight) missingFields.push('cân nặng');

        if (!val.service_id) missingFields.push('dịch vụ');
        if (!val.country) missingFields.push('quốc gia');

        if (val.is_insurance === true) {
          if (!val.price) return this.shippingFee = 'Thiếu giá trị đơn hàng';
        }
        if (missingFields.length > 0) return this.shippingFee = 'Thiếu ' + missingFields.join(', ')

        if (this.item.service_id === this.CONSTANTS.SERVICE_TYPE.SHIP_EXPRESS) {
          return this.shippingFee = 'Dịch vụ này sẽ tính giá khi nhận hàng';
        }
        let timeToCheck = new Date().getTime();
        this.checkShippingFeeTime = JSON.parse(JSON.stringify(timeToCheck));
        this.calcShippingFees({
          ...val,
          silent: true
        }).then(result => {
          if(this.checkShippingFeeTime !== timeToCheck) return;
          console.log('Update price');
          let shippingFee = result.data;
          if (shippingFee.error) this.shippingFee = shippingFee.message;
          else {
            let totalFee = parseInt(shippingFee.data.total);
            if (totalFee < 1 || isNaN(totalFee)) {
              this.shippingFee = 'Không tìm được giá';
            } else this.shippingFee = totalFee;
          }
        });

      },
      deep: true,
      immediate: true
    },
    showModal: {
      handler() {
        if (!this.showModal) {
          this.$emit('resetFlagCopy')
        } else if (this.isCopyOrder) this.handleCopyOrder();
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
