<template>
  <v-card tile :elevation="0" class="crud-box list-orders px-4">
    <h1>Manage Orders</h1>
    <v-layout wrap class="pt-4">
      <v-btn color="success" class="mr-2" @click="onClickNew">
        <v-icon>mdi-plus</v-icon>
        New
      </v-btn>
      <!--      <v-btn color="primary" outlined class="mr-2" @click="onClickImport">
              <v-icon>mdi-database-import</v-icon>
              Import
            </v-btn>-->
      <v-btn color="primary" outlined class="mr-2" @click="exportItems()">
        <v-icon>mdi-database-export</v-icon>
        Export
      </v-btn>
      <!--      <v-btn color="teal" class="mr-2 text-white btn-unpaid-order" @click="onClickSelectOrderUnpaid">
              <v-icon>mdi-check</v-icon>
              Select Unpaid Order
            </v-btn>
            <v-btn color="error" v-if="pickedItems.length > 0" class="mr-2" @click="onClickDeleteAll">
              <v-icon>mdi-cancel</v-icon>
              Cancel Orders
            </v-btn>
            <v-btn v-if="pickedItems.length > 0"
                   color="info" class="mr-2"
                   @click="onClickPayMultiOrders">
              <v-icon>mdi-currency-usd</v-icon>
              Pay Orders
            </v-btn>-->
      <v-btn color="primary" class="mr-2" @click="onClickDownloadLabel">
        <v-icon>mdi-database-export</v-icon>
        Download Label
      </v-btn>
      <v-btn v-if="pickedItems.length > 0 && canBuyTracking && $auth.user().role_id== CONSTANTS.ROLES.SUPER_ADMINISTRATOR"
             color="primary"
             class="mr-2"
             @click="onClickBuyTracking">
        Buy Tracking
      </v-btn>
    </v-layout>
    <v-layout class="switch-search">
      <v-switch
        v-model="showSearch"
        :label="`Show field search`"
      ></v-switch>
    </v-layout>
    <v-layout v-show="showSearch" wrap class="px-4 pt-4">
      <!---->
      <v-layout wrap class="search-fields search-fields-page">
        <v-text-field dense outlined @keyup.enter="doSearch"
                      class="mr-2" label="Search Customer"
                      v-if="UserRole === CONSTANTS.ROLES.SUPER_ADMINISTRATOR"
                      v-model="searchFields['customer.email'].value"/>
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2"
                      label="Search Order Name"
                      v-model="searchFields.name.value"/>
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2"
                      label="Search Order Code"
                      v-model="searchFields.code.value"/>
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2"
                      label="Search Tracking Number"
                      v-model="searchFields.tracking_number.value"/>
        <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2"
                      v-if="UserRole === CONSTANTS.ROLES.SUPER_ADMINISTRATOR"
                      label="Search Warehouse"
                      v-model="searchFields.warehouse_name.value"/>
        <v-menu
          v-model="showBuyTrackingDatePicker"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field label="Ngày mua tracking" @click:clear="clearBuyTrackingSearch()" class="mr-2" clearable dense outlined readonly
                          v-on="on" v-bind="attrs" :value="DateRangeText(buyTrackingDates)"
                          @click="showBuyTrackingDatePicker = true"></v-text-field>
          </template>
          <v-date-picker v-model="buyTrackingDates" range></v-date-picker>
        </v-menu>
        <!--        <v-menu
                  v-model="showCreatedAtOrderDatePicker"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field label="Ngày tạo order" class="mr-2" @click:clear="clearCreatedOrderSearch()" clearable dense outlined readonly
                                  v-on="on" v-bind="attrs" :value="DateRangeText(createdDates)"
                                  @click="showCreatedAtOrderDatePicker = true"></v-text-field>
                  </template>
                  <v-date-picker v-model="createdDates" range></v-date-picker>
                </v-menu>-->
        <!--        <v-autocomplete dense outlined @keyup.enter="doSearch" class="mr-2"
                                label="Search Status"
                                :items="listOrderStatus" v-model="searchFields.status.value"/>-->
        <!--        <v-autocomplete dense outlined @keyup.enter="doSearch"
                                        class="mr-2" label="Buy Tracking Status"
                                        :items="listBuyTrackingStatus" v-model="searchFields.buy_tracking_status.value"/>
                        <v-autocomplete dense outlined @keyup.enter="doSearch" class="mr-2"
                                        label="Search Service" style="width: 175px"
                                        :items="listServices" v-model="searchFields.service_id.value"/>-->
        <v-autocomplete dense outlined @keyup.enter="doSearch" class="mr-2"
                        label="Receiver country" style="width: 175px" item-text="name"
                        :items="listCountry" v-model="searchFields.receiver_country_id.value" item-value="id"/>
        <!--        <v-menu
                  v-model="showDepartDatePicker"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field label="Ngày Depart" class="mr-2"  @click:clear="clearDepartDateSearch()"  clearable dense outlined readonly
                                  v-on="on" v-bind="attrs" :value="DateRangeText(departDates)"
                                  @click="showDepartDatePicker = true"></v-text-field>
                  </template>
                  <v-date-picker v-model="departDates" range></v-date-picker>
                </v-menu>-->
        <!--        <v-menu
                  v-model="showDeliveredDatePicker"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field label="Ngày Delivered" @click:clear="clearDeliveredDateSearch()" class="mr-2" clearable dense outlined readonly
                                  v-on="on" v-bind="attrs" :value="DateRangeText(deliveredDates)"
                                  @click="showDeliveredDatePicker = true"></v-text-field>
                  </template>
                  <v-date-picker v-model="deliveredDates" range></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="showReceiveDatePicker"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field label="Receive Date" @click:clear="clearReceivedDateSearch()" class="mr-2" clearable dense outlined readonly
                                  v-on="on" v-bind="attrs" :value="DateRangeText(receivedDates)"
                                  @click="showReceiveDatePicker = true"></v-text-field>
                  </template>
                  <v-date-picker v-model="receivedDates" range></v-date-picker>
                </v-menu>-->
        <v-spacer style="flex-grow: 100000000000000 !important;"></v-spacer>
      </v-layout>
      <v-btn color="primary" @click="doSearch">
        <v-icon>mdi-magnify</v-icon>
        Search
      </v-btn>
    </v-layout>

    <!--card content-->
    <v-card tile :elevation="1" :disabled="tblLoading" outlined class="block-tbl mx-4">
      <v-data-table :headers="tblHeaderFilterByRole" :items="listItems" :server-items-length="tblPagination.total"
                    :loading="tblLoading"
                    mobile-breakpoint="0"
                    :footer-props="tblConfig.footerProps" @dblclick:row="onClickRow" :options.sync="tblOptions"
                    :loading-text="tblConfig.text.loading" v-model="pickedItems" :show-select="tblConfig.showSelect">
        <template v-slot:no-data>
          {{ tblConfig.text.noItem }}
        </template>

        <template v-slot:item.no="{item}">
          {{ getIndexOfItem(item) }}
        </template>

        <template v-slot:item.name="{item}">
          <v-tooltip bottom v-if="item.note">
            <template v-slot:activator="{ on, attrs }">
              <div class="block-item-name" v-bind="attrs" v-on="on">
                <div class="item-name">{{ item.name }}</div>
                <div class="item-code">{{ item.code }}</div>
              </div>
            </template>
            <span>{{ item.note }}</span>
          </v-tooltip>
          <template v-else>
            <div class="block-item-name">
              <div class="item-name">{{ item.name }}</div>
              <div class="item-code">{{ item.code }}</div>
            </div>
          </template>
        </template>

        <template v-slot:item.service="{item}">
          <v-tooltip bottom v-if="item.employee_note">
            <template v-slot:activator="{ on, attrs }">
              <div class="item-service-name" v-bind="attrs" v-on="on">
                {{ serviceGetName(item.service_id) }}
              </div>
            </template>
            <span>{{ item.employee_note }}</span>
          </v-tooltip>
          <template v-else>
            {{ serviceGetName(item.service_id) }}
          </template>
        </template>
        <template v-slot:item.receiver_country_id="{item}">
          {{ item.receiver_country_id ? countryById(item.receiver_country_id).name : '' }}
        </template>
        <template v-slot:item.paid_fee="{item}">
          <money-format :value="getPaidFee(item)" style="display: inline-block;"
                        :hide-subunits=true :locale="'vi'" :currency-code="'VND'"></money-format>
        </template>
        <template v-slot:item.remain_fee="{item}">
          <money-format :value="getRemainFee(item)" style="display: inline-block;"
                        :hide-subunits=true :locale="'vi'" :currency-code="'VND'"></money-format>
        </template>
        <template v-slot:item.total_fee="{item}">
          <money-format :value="getTotalFee(item)" style="display: inline-block;"
                        :hide-subunits=true :locale="'vi'" :currency-code="'VND'"></money-format>
        </template>
        <template v-slot:item.status="{item}">
          <div v-if="item.departed_date && item.status === CONSTANTS.ORDER_STATUS.PACKAGED_WAIT_TRANSIT">Departed</div>
          <div v-else v-html="orderGetStatusText(item.status).replace(' - ', '<br>')"></div>
        </template>

        <template v-slot:item.buy_tracking_status="{item}">
          <span :class="{
            'green--text' : item.buy_tracking_status === CONSTANTS.TRACKING_NUMBER_BUY_STATUS.BUY_SUCCESS,
            'red--text' : item.buy_tracking_status === CONSTANTS.TRACKING_NUMBER_BUY_STATUS.BUY_FAIL
          }">
              {{ buyTrackingGetStatusText(item.buy_tracking_status) }}
            </span>
        </template>

        <template v-slot:item.tracking_number="{item}">
          <span>
            <a target="_blank" :href="'http://tracking.epacket.vn/#/tracking/manage?tracking_no=' + item.code">{{ item.tracking_number }}</a>
          </span>
          <div style="font-size: 10px; white-space: nowrap">
            {{ item.buy_tracking_number_date ? 'Buy at ' + dateFilter(item.buy_tracking_number_date) : '' }}
          </div>
        </template>

        <template v-slot:item.tracking_master="{item}">
          {{ item.big_container ? item.big_container.tracking_number : '' }}
        </template>

        <template v-slot:item.departed_date="{item}">
          {{ departedDate(item) }}
        </template>

        <template v-slot:item.delivered_date="{item}">
          {{ deliveredDate(item) }}
        </template>
        <template v-slot:item.created_at="{item}">
          {{ createdDate(item) }}
        </template>
        <template v-slot:item.customer_email="{item}">
          {{ item.customer.email }}
        </template>
        <template v-slot:item.customer_note="{item}">
          <v-tooltip bottom v-if="item.customer_note" max-width="200px">
            <template v-slot:activator="{ on, attrs }">
              <div class="order-customer-note" v-bind="attrs" v-on="on">
                <span class="truncate">
              {{ item.customer_note }}
            </span>
              </div>
            </template>
            <span>{{ item.customer_note }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.actions="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="onClickDetail(props)" v-bind="attrs" v-on="on" class="mr-2" color="primary">
                mdi-view-list
              </v-icon>
            </template>
            <span>Chi tiết đơn hàng</span>
          </v-tooltip>

          <v-tooltip bottom v-if="canEditOrder(props)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="onClickEdit(props)" v-bind="attrs" v-on="on" class="mr-2" color="success">
                mdi-pencil
              </v-icon>
            </template>
            <span>Sửa đổi thông tin</span>
          </v-tooltip>
          <v-tooltip bottom v-if="needPayOrder(props)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="onClickPayOrder(props)" v-bind="attrs" v-on="on" color="error">mdi-currency-usd
              </v-icon>
            </template>
            <span>Thanh toán</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-layout wrap class="px-4">
      <!--      <div style="display: flex;align-items: center;margin-right: 1rem;font-weight: bold;">-->
      <!--        <label>Search Fields:</label>-->
      <!--      </div>-->
      <!--      <v-checkbox v-model="searchFields.show_order_canceled.value" style="width: 195px; display: inline-block;">-->
      <!--        <template v-slot:label>-->
      <!--          <span class="red&#45;&#45;text"><b>{{ searchFields.show_order_canceled.display_name }}</b></span>-->
      <!--        </template>-->
      <!--      </v-checkbox>-->
      <!--      <template v-for="(field, idx) in toggleableSearchFields">-->
      <!--        <v-checkbox v-if="!searchFields[field].display_role || searchFields[field].display_role.indexOf(UserRole) > -1"-->
      <!--                    v-model="searchFields[field].display" :key="idx" style="margin-left: 1rem">-->
      <!--          <template v-slot:label>-->
      <!--            {{ searchFields[field].display_name }}-->
      <!--          </template>-->
      <!--        </v-checkbox>-->
      <!--      </template>-->
    </v-layout>

    <v-dialog v-model="dialog.create" :width="'80%'">
      <CreateItem :onCreated="onCreated" :isCopyOrder="isCopyOrder" :showModal="dialog.create" @resetFlagCopy="resetFlagCopy" :selectedItem="selectedItem"
                  :onCancel="()=>{dialog.create=false}"></CreateItem>
    </v-dialog>
    <v-dialog v-model="dialog.detail" :width="'80%'">
      <DetailItem :selectedItem="selectedItem" :onClose="()=>{dialog.detail=false}" :onClickPrintLable="onClickPrintLable" @clickCopy="clickCopy"></DetailItem>
    </v-dialog>
    <v-dialog v-model="dialog.update" :width="'80%'">
      <UpdateItem :onUpdated="onUpdated" :selectedItem="selectedItem" :dialogUpdate="dialog.update"
                  :onCancel="()=>{dialog.update=false}"></UpdateItem>
    </v-dialog>
    <v-dialog v-model="dialog.delete" :width="400">
      <DeleteItem :idsToDelete="idsToDelete" :onDeleted="onDeleted" :onCancel="()=>{dialog.delete=false}"></DeleteItem>
    </v-dialog>
    <v-dialog v-model="dialog.payOrders" :width="400">
      <PayOrder :idsToPay="idsToPay" :onPaid="onPaid" :onCancel="()=>{dialog.payOrders=false}"></PayOrder>
    </v-dialog>
    <v-dialog v-model="dialog.import" :width="600">
      <ImportItem :onImported="onImported" :onCancel="()=>{dialog.import=false}"></ImportItem>
    </v-dialog>
    <v-dialog v-model="dialog.printLabel" :width="600">
      <ConfirmPrintLabel :idsToPrintLabel="idsToPrintLabel" :onCancel="()=>{dialog.printLabel=false}"></ConfirmPrintLabel>
    </v-dialog>
    <v-dialog v-model="dialog.exportOrder" :width="600">
      <ModalExportOrder :picked="pickedItems" :statusSync="dialog.exportOrder" :option="tblOptions" :searchData="searchFields"
                        :onCancel="()=>{dialog.exportOrder=false}"></ModalExportOrder>
    </v-dialog>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import CreateItem from "./Create";
import DetailItem from "./Detail";
import UpdateItem from "./Update";
import DeleteItem from "./Delete";
import PayOrder from "./PayOrder";
import ImportItem from "./Import";
import indexMixin from './indexMixin';
import ConfirmPrintLabel from "@/admin/pages/ManageOrders/PrintLabel/ConfirmPrintLabel";
import ModalExportOrder from "@/admin/pages/ManageOrders/Modal/ModalExportOrder";
import MoneyFormat from "vue-money-format";
import moment from 'moment'
import CONSTANTS from '@/config/constants'
import * as Promise from "bluebird";
import * as JsZip from "jszip";
import * as FileSaver from "file-saver";

export default {
  name: 'ManageOrders',
  components: {ConfirmPrintLabel, CreateItem, DetailItem, UpdateItem, DeleteItem, PayOrder, ImportItem, MoneyFormat, ModalExportOrder},
  mixins: [indexMixin],
  data: () => ({
    searchFields: {
      'customer.email': {
        value: '',
        type: 'like',
        display: true,
        display_name: 'Customer Email',
        display_role: [CONSTANTS.ROLES.SUPER_ADMINISTRATOR, CONSTANTS.ROLES.RECEIVER, CONSTANTS.ROLES.PACKAGER]
      },
      name: {
        value: '',
        type: 'like',
        display: false,
        display_name: 'Order Name',
      },
      show_order_canceled: {
        value: false,
        type: '=',
        display: true,
        display_name: 'Show Orders Canceled',
        display_role: []
      },
      code: {
        value: '',
        type: 'like',
        display: true,
        display_name: 'Order Code',
      },
      tracking_number: {
        value: '',
        type: 'like',
        display: false,
        display_name: 'Tracking Number',
      },
      warehouse_name: {
        value: '',
        type: 'like',
        display: false,
        display_name: 'Warehouse name',
      },
      status: {
        value: '',
        type: '=',
        display: true,
        display_name: 'Order Status',
      },
      receiver_country_id: {
        value: '',
        type: '=',
        display: true,
        display_name: 'Receiver Country',
      },
      buy_tracking_status: {
        value: '',
        type: '=',
        display: false,
        display_name: 'Buy Tracking Status',
      },
      service_id: {
        value: '',
        type: 'like',
        display: true,
        display_name: 'Service',
      },
      search_by_receiver: {
        value: false,
        type: '=',
        display: false,
        force_hidden: true
      },
      departed_date_from: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      departed_date_to: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      delivered_date_from: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      delivered_date_to: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      received_date_from: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      received_date_to: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      created_date_from: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      created_date_to: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      buy_tracking_date_from: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
      buy_tracking_date_to: {
        value: '',
        type: '=',
        display: false,
        force_hidden: true
      },
    },
    searchWith: 'customer;orderItems;bigContainer;orderFees;payments',
    tblHeader: [
      {
        text: 'No',
        value: 'no',
        sortable: false,
        groupable: false,
        width: '55px',
        align: 'start',
      },
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Service',
        value: 'service',
      },
      {
        text: 'Receiver Country',
        value: 'receiver_country_id',
        sortable: false
      },
      {
        text: 'Buy Tracking Status',
        value: 'buy_tracking_status',
        sortable: false
      },
      {
        text: 'Tracking Number',
        value: 'tracking_number',
        sortable: false
      },
      {
        text: 'Total Fee',
        value: 'total_fee',
      },
      {
        text: 'Paid Fee',
        value: 'paid_fee',
      },
      {
        text: 'Remain Fee',
        value: 'remain_fee',
      },
      {
        text: 'Status',
        value: 'status',
        sortable: false,
        width: '155px',
      },
      {
        text: 'Warehouse name',
        value: 'warehouse_name',
      },
      /*{
          text: 'Tracking Master',
          value: 'tracking_master',
          sortable: false
      },
      {
        text: 'Departed Date',
        value: 'departed_date',
      },
      {
        text: 'Delivered Date',
        value: 'delivered_date',
      },
      {
          text: 'Receive Date',
          value: 'receive_date',
      },*/
      {
        text: 'Customer email',
        value: 'customer_email',
      },
      {
        text: 'Customer note',
        value: 'customer_note',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        groupable: false,
        width: '111px',
        align: 'center',
      }
    ],
    showBuyTrackingDatePicker: false,
    showDepartDatePicker: false,
    showDeliveredDatePicker: false,
    showReceiveDatePicker: false,
    showCreatedAtOrderDatePicker: false,
    buyTrackingDates: [],
    createdDates: [],
    departDates: [],
    deliveredDates: [],
    receivedDates: [],
    idsToPay: [],
    idsToExport: [],
    isCopyOrder: false,
    showSearch: true,
  }),
  computed: {
    ...mapGetters({
      items: 'order/all',
      tblPagination: 'order/pagination',
      services: 'service/all',
      serviceGetName: 'service/serviceName',
      listOrderStatus: 'order/listOrderStatus',
      orderGetStatusText: 'order/getStatusText',
      buyTrackingGetStatusText: 'order/buyTrackingGetStatusText',
      listBuyTrackingStatus: 'order/listBuyTrackingStatus',
      countryById: 'country/getById',
      countries: 'country/all',
      authenticatedUser: 'user/authenticatedUser',
    }),
    tblHeaderFilterByRole() {
      const header = JSON.parse(JSON.stringify(this.tblHeader));
      if (this.UserRole === CONSTANTS.ROLES.CUSTOMER) {
        const findIndexStatusTracking = header.findIndex((t) => t.value === 'buy_tracking_status');
        if (findIndexStatusTracking > -1) {
          header.splice(findIndexStatusTracking, 1);
        }
        const findIndexCustomerEmail = header.findIndex((t) => t.value === 'customer_email');
        if (findIndexCustomerEmail > -1) {
          header.splice(findIndexCustomerEmail, 1);
        }
        const findIndexWarehouseName = header.findIndex((t) => t.value === 'warehouse_name');
        if (findIndexWarehouseName > -1) {
          header.splice(findIndexWarehouseName, 1);
        }
        const findIndexTrackingMaster = header.findIndex((t) => t.value === 'tracking_master');
        if (findIndexTrackingMaster > -1) {
          header.splice(findIndexTrackingMaster, 1);
        }
      }
      return header;
    },
    listCountry() {
      return [{id: '', name: '------'}, ...this.countries];
    },
    listServices() {
      let listItems = [
        {text: 'All Service', value: ''}
      ]
      this.services.forEach(service => {
        listItems.push({
          text: service.name,
          value: service.id
        })
      });
      return listItems;
    },
    DateRangeText() {
      return dateArr => {
        return dateArr.join(' ~ ')
      }
    },
    departedDate() {
      return item => {
        try {
          return item.departed_date.substr(0, 10)
        } catch (e) {
          return ''
        }
      }
    },
    deliveredDate() {
      return item => {
        try {
          return item.delivered_date.substr(0, 10)
        } catch (e) {
          return ''
        }
      }
    },
    createdDate() {
      return item => {
        try {
          return item.created_at.substr(0, 10)
        } catch (e) {
          return ''
        }
      }
    },
    canPayMultiOrders() {
      return this.pickedItems.filter(order => {
        let payments = order.payments.filter(p => p.status === this.CONSTANTS.PAYMENT_STATUS.PAID && p.payment_type === this.CONSTANTS.PAYMENT_TYPE.PAY_ORDER);
        let paidFee = 0;
        payments.forEach(p => paidFee += parseFloat(p.amount));
        let totalFee = this.getTotalFee(order);
        return parseInt(totalFee) - parseInt(paidFee) === 0;
      }).length === 0
    },
    canBuyTracking() {
      return true;
      /*return this.pickedItems.filter(item => {
        return item.buy_tracking_status === this.CONSTANTS.TRACKING_NUMBER_BUY_STATUS.BUY_SUCCESS || item.buy_tracking_status === this.CONSTANTS.TRACKING_NUMBER_BUY_STATUS.NEED_BUY
      }).length === 0*/
    },

    getPaidFee() {
      return order => {
        let payments = order.payments.filter(p => p.status === this.CONSTANTS.PAYMENT_STATUS.PAID && p.payment_type === this.CONSTANTS.PAYMENT_TYPE.PAY_ORDER);
        let payment_refunds = order.payments.filter(p => p.status === this.CONSTANTS.PAYMENT_STATUS.REFUND && p.payment_type === this.CONSTANTS.PAYMENT_TYPE.PAY_ORDER);
        let paidFee = 0;
        let refund = 0;
        payments.forEach(p => paidFee += parseFloat(p.amount));
        payment_refunds.forEach(p => refund += parseFloat(p.amount));
        return paidFee - refund;
      }
    },
    getTotalFee() {
      return order => {
        let totalFee = 0;
        order.order_fees.forEach(f => totalFee += parseFloat(f.fee_amount));
        return parseInt(totalFee);
      }
    },
    getRemainFee() {
      return order => {
        let payments = order.payments.filter(p => p.status === this.CONSTANTS.PAYMENT_STATUS.PAID && p.payment_type === this.CONSTANTS.PAYMENT_TYPE.PAY_ORDER);
        let payment_refunds = order.payments.filter(p => p.status === this.CONSTANTS.PAYMENT_STATUS.REFUND && p.payment_type === this.CONSTANTS.PAYMENT_TYPE.PAY_ORDER);
        let paidFee = 0;
        let refund = 0;
        payments.forEach(p => paidFee += parseFloat(p.amount));
        payment_refunds.forEach(p => refund += parseFloat(p.amount));
        let totalFee = this.getTotalFee(order);
        return totalFee + refund - paidFee;
      }
    },
    toggleableSearchFields() {
      return Object.keys(this.searchFields).filter(sf => this.searchFields[sf].force_hidden !== true);
    },
  },
  methods: {
    ...mapActions({
      getLabelData: 'order/getLabelData',
      getListItems: 'order/get',
      exportListItems: 'order/exportItem',
      requestBuyTrackingNumber: 'order/requestBuyTrackingNumber',
    }),
    onClickPayOrder(props) {
      this.dialog.payOrders = true;
      this.idsToPay = [props.item.id];
    },
    async downloadByGroup(urls, files_per_group = 5) {
      return Promise.map(
        urls,
        async url => {
          return await this.download(url);
        },
        {concurrency: files_per_group}
      );
    },
    async download(url) {
      return fetch(url).then(resp => resp.blob());
    },
    // async exportZip(blobs) {
    //   const zip = JsZip();
    //   blobs.forEach((blob, i) => {
    //     zip.file(`file-${i}.png`, blob);
    //   });
    //   zip.generateAsync({type: 'blob'}).then(zipFile => {
    //     const currentDate = new Date().getTime();
    //     const fileName = `Label-${currentDate}.zip`;
    //     return FileSaver.saveAs(zipFile, fileName);
    //   });
    // },
    // async downloadAndZip(urls) {
    //   return this.downloadByGroup(urls, 5).then(this.exportZip);
    // },
    // async convertBlob(labels) {
    //   let blobs = [];
    //   for (const base64 of labels) {
    //     let url = "data:image/png;base64," + base64
    //     await fetch(url)
    //       .then(res => res.blob())
    //       .then((data) => {
    //         blobs.push(data)
    //       })
    //   }
    //   return blobs;
    // },
    async onClickDownloadLabel() {
      this.dialog.exportOrder = true;

      // let ids = this.pickedItems.map(i => i.id);
      // this.getLabelData({ids: ids}).then(async (data) => {
      //   let blobs = [];
      //   for (const labels of data.data.data) {
      //     const labelArr = JSON.parse(labels)
      //     let res = await this.convertBlob(labelArr)
      //     blobs = [...blobs, ...res]
      //   }
      //   if (blobs.length < 1) return this.noticeError('No label!');
      //   await this.exportZip(blobs)
      // });


      // this.downloadAndZip(['http://127.0.0.1:8000/webapp/img/LOGO.ee164270.png', 'http://127.0.0.1:8000/webapp/img/(1).495b8af4.png'])
    },
    clickCopy() {
      this.dialog.detail = false;
      this.dialog.create = true;
      this.isCopyOrder = true;
    },
    onClickPrintLable() {
      if (this.selectedItem && this.selectedItem.id) {
        this.dialog.detail = false;
        this.dialog.printLabel = true;
        this.idsToPrintLabel = [this.selectedItem.id]
      }
    },
    resetFlagCopy() {
      this.isCopyOrder = false;
    },
    dateFilter(value) {
      if (!value) return;
      value = moment(value);
      return value.format('YYYY-MM-DD hh:mm');
    },
    exportItems() {
      this.dialog.exportOrder = true;
    },
    clearBuyTrackingSearch() {
      this.searchFields.buy_tracking_date_from.value = '';
      this.searchFields.buy_tracking_date_to.value = '';
    },
    clearCreatedOrderSearch() {
      this.searchFields.created_date_to.value = '';
      this.searchFields.created_date_from.value = '';
    },
    clearDepartDateSearch() {
      this.searchFields.departed_date_to.value = '';
      this.searchFields.delivered_date_from.value = '';
    },
    clearDeliveredDateSearch() {
      this.searchFields.delivered_date_to.value = '';
      this.searchFields.delivered_date_from.value = '';
    },
    clearReceivedDateSearch() {
      this.searchFields.received_date_to.value = '';
      this.searchFields.received_date_from.value = '';
    },
  },
  watch: {
    departDates: {
      handler(val) {
        if (!Array.isArray(val)) return;
        if (val[0] && !isNaN(Date.parse(val[0]))) this.searchFields.departed_date_from.value = val[0];
        if (val[1] && !isNaN(Date.parse(val[1]))) this.searchFields.departed_date_to.value = val[1];
      },
      deep: true,
      immediate: true
    },
    deliveredDates: {
      handler(val) {
        if (!Array.isArray(val)) return;
        if (val[0] && !isNaN(Date.parse(val[0]))) this.searchFields.delivered_date_from.value = val[0];
        if (val[1] && !isNaN(Date.parse(val[1]))) this.searchFields.delivered_date_to.value = val[1];
      },
      deep: true,
      immediate: true
    },
    receivedDates: {
      handler(val) {
        if (!Array.isArray(val)) return;
        if (val[0] && !isNaN(Date.parse(val[0]))) this.searchFields.received_date_from.value = val[0];
        if (val[1] && !isNaN(Date.parse(val[1]))) this.searchFields.received_date_to.value = val[1];
      },
      deep: true,
      immediate: true
    },
    createdDates: {
      handler(val) {
        if (!Array.isArray(val)) return;
        if (val[0] && !isNaN(Date.parse(val[0]))) this.searchFields.created_date_from.value = val[0];
        if (val[1] && !isNaN(Date.parse(val[1]))) this.searchFields.created_date_to.value = val[1];
      },
      deep: true,
      immediate: true
    },
    buyTrackingDates: {
      handler(val) {
        if (!Array.isArray(val)) return;
        if (val[0] && !isNaN(Date.parse(val[0]))) this.searchFields.buy_tracking_date_from.value = val[0];
        if (val[1] && !isNaN(Date.parse(val[1]))) this.searchFields.buy_tracking_date_to.value = val[1];
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    // if(!this.authenticatedUser.id_card || !this.authenticatedUser.phone || this.authenticatedUser.id_card.trim().length === 0 || this.authenticatedUser.phone.trim().length === 0) {
    //   this.noticeWarning('Phone, Identity Card required!');
    //   this.$router.push({name: 'Profile'})
    //   return;
    // }
    this.searchFields.search_by_receiver.value = this.UserRole === CONSTANTS.ROLES.RECEIVER
  }
}
</script>
<style scoped lang="scss">
.list-orders::v-deep {
  .v-data-table-header th {
    white-space: nowrap;
  }

  .order-customer-note {
    display: flex;
    max-width: 150px;

    .truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.block-item-name {
  .item-name {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .item-code {
    font-size: 0.8rem;
  }
}

.item-service-name {
  white-space: nowrap;
}

.switch-search::v-deep {
  .v-messages {
    display: none;
  }
}

.btn-unpaid-order {
  ::v-deep span {
    color: #fff !important;
  }
}
</style>
